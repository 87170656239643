import React, { useRef, useEffect, useImperativeHandle } from 'react';
import Blockly from 'blockly/core'; // Correct import for blockly

import DarkTheme from '@blockly/theme-dark';


// Network Type block
Blockly.Blocks['network_type'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Network Type")
        .appendField(new Blockly.FieldDropdown([["CNN", "CNN"], ["MLP", "MLP"], ["RNN", "RNN"], ["LSTM", "LSTM"]]), "network_type");
    this.appendDummyInput()
        .appendField("Input Dimension")
        .appendField(new Blockly.FieldNumber(0), "input_dim");
    this.appendDummyInput()
        .appendField("Output Dimension")
        .appendField(new Blockly.FieldNumber(0), "output_dim");
    this.appendDummyInput()
        .appendField("Activation Function")
        .appendField(new Blockly.FieldDropdown([["ReLU", "ReLU"], ["Sigmoid", "Sigmoid"], ["Tanh", "Tanh"], ["Softmax", "Softmax"]]), "activation_function");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(20); 
    this.setTooltip("This block represents the type of the network layer. It includes activation function and input/output dimensions.");
  }
};

// Encoder block
Blockly.Blocks['encoder'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Encoder");
    this.appendStatementInput("OPTIONS").setCheck(['NetworkType']);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(60);
    this.setTooltip("This block represents the encoder part of the model. It transforms the input into a lower-dimensional latent space.");
  }
};

Blockly.Blocks['prediction'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Model Prediction");
      this.appendDummyInput()
          .appendField("Dimension")
          .appendField(new Blockly.FieldNumber(0), "prediction_dim");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("This block represents the model's prediction. You can specify its dimension.");
    }
  };
  
  Blockly.Blocks['ground_truth'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Ground Truth");
      this.appendDummyInput()
          .appendField("Dimension")
          .appendField(new Blockly.FieldNumber(0), "ground_truth_dim");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("This block represents the ground truth or target values. You can specify its dimension.");
    }
  };
  
  Blockly.Blocks['training_loop'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Training Loop");
      this.appendDummyInput()
          .appendField("Loss Type")
          .appendField(new Blockly.FieldDropdown([["MSE", "MSE"], ["Cross-Entropy", "CrossEntropy"], ["Log Loss", "LogLoss"]]), "loss_type");
      this.appendDummyInput()
          .appendField("Optimizer")
          .appendField(new Blockly.FieldDropdown([["SGD", "SGD"], ["Adam", "Adam"], ["RMSprop", "RMSprop"]]), "optimizer");
      this.appendDummyInput()
          .appendField("Epochs")
          .appendField(new Blockly.FieldNumber(10), "epochs");
      this.appendStatementInput("PREDICTION")
          .appendField("Model Prediction")
          .setCheck(['Prediction']);
      this.appendStatementInput("GROUND_TRUTH")
          .appendField("Ground Truth")
          .setCheck(['GroundTruth']);
      this.appendStatementInput("DO")
          .appendField("do")
          .setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip(".");
    }
  };
  
  Blockly.Blocks['train_step'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Train Step");
      this.appendDummyInput()
          .appendField("Batch Size")
          .appendField(new Blockly.FieldNumber(32), "batch_size");
      this.setPreviousStatement(true, "TrainStep");
      this.setNextStatement(true, "TrainStep");
      this.setColour(60);
      this.setTooltip("This block represents a single training step.");
    }
  };
  
Blockly.Blocks['input_data'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Input Data")
        .appendField(new Blockly.FieldNumber(0), "input_data_dim");
    this.setOutput(true, "InputData");
    this.setColour(20);
    this.setTooltip("This block represents the input data to the model. You can specify its dimension.");
  }
};

// Latent space block
Blockly.Blocks['latent_space'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Latent Space");
    this.appendStatementInput("OPTIONS").setCheck(['NetworkType']);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(60);
    this.setTooltip(".");
  }
};

// Decoder block
Blockly.Blocks['decoder'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Decoder");
    this.appendStatementInput("OPTIONS").setCheck(['NetworkType']);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(60);
    this.setTooltip(".");
  }
};

// Model block
Blockly.Blocks['model'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Model");
    this.appendStatementInput("OPTIONS").setCheck(['NetworkType']);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip(".");
  }
};

Blockly.Blocks['latent_dim'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Latent Dimension")
        .appendField(new Blockly.FieldNumber(0), "latent_dim");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(120);
  }
};

Blockly.Blocks['training'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Train VAE");
    this.appendStatementInput("TRAINING_OPTIONS")
        .setCheck(null)
        .appendField("with options");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
  }
};

Blockly.Blocks['data_preparation'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Data Preparation");
      this.appendDummyInput()
          .appendField("Data Scaling")
          .appendField(new Blockly.FieldDropdown([["Standard", "Standard"], ["MinMax", "MinMax"], ["Robust", "Robust"]]), "data_scaling");
      this.appendDummyInput()
          .appendField("Train-Test Split")
          .appendField(new Blockly.FieldNumber(0.2), "train_test_split");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(40);
      this.setTooltip("This block represents the data preparation steps. It includes options for data scaling and splitting the dataset into training and testing sets.");
    }
  };

Blockly.Blocks['evaluation_metric'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Evaluation Metric")
          .appendField(new Blockly.FieldDropdown([["Accuracy", "Accuracy"], ["Precision", "Precision"], ["Recall", "Recall"], ["F1 Score", "F1Score"]]), "evaluation_metric");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(260);
      this.setTooltip("This block represents the evaluation metric to be used for assessing the performance of the model.");
    }
  };

Blockly.Blocks['epochs'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Epochs")
        .appendField(new Blockly.FieldNumber(10), "epochs");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(20);
  }
};

Blockly.Blocks['learning_rate'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Learning Rate")
        .appendField(new Blockly.FieldNumber(0.001), "lr");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(20);
  }
};

// Sampling block
Blockly.Blocks['sampling'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Sample from");
    this.setPreviousStatement(true, null);
    this.setColour(60);
  }
};

Blockly.Blocks['model_save'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Save Model");
      this.appendDummyInput()
          .appendField("Model Name")
          .appendField(new Blockly.FieldTextInput("my_model"), "model_name");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(80);
      this.setTooltip("This block represents the operation of saving the trained model.");
    }
  };

Blockly.Blocks['model_load'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("Load Model");
      this.appendDummyInput()
          .appendField("Model Name")
          .appendField(new Blockly.FieldTextInput("my_model"), "model_name");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(80);
      this.setTooltip("This block represents the operation of loading a previously saved model.");
    }
  };

const BlocklyComponent = React.forwardRef(({ workspaceXML, onChange }, ref) => {
  const workspaceRef = useRef(null);
  const blocklyDivRef = useRef(null);

  const toolboxXML = `
  <xml xmlns="http://www.w3.org/1999/xhtml" id="toolbox" style="display: none;">
  <block type="data_preparation"></block>
  <block type="decoder"></block>
  <block type="encoder"></block>
  <block type="epochs"></block>
  <block type="evaluation_metric"></block>
  <block type="ground_truth"></block>
  <block type="input_data"></block>
  <block type="latent_dim"></block>
  <block type="latent_space"></block>
  <block type="learning_rate"></block>
  <block type="model"></block>
  <block type="model_load"></block>
  <block type="model_save"></block>
  <block type="network_type"></block>
  <block type="prediction"></block>
  <block type="sampling"></block>
  <block type="train_step"></block>
  <block type="training"></block>
  <block type="training_loop"></block>
  
  </xml>
`;

  useEffect(() => {
    const workspace = Blockly.inject(blocklyDivRef.current, {
   toolbox: toolboxXML,
      scrollbars: true,
      theme: DarkTheme,  // Use the imported dark theme

    });

    if (workspaceXML) {
      Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(workspaceXML), workspace);
    }

    const handleChange = (event) => {
      if (event.type === Blockly.Events.UI) {
        return;
      }

      const newWorkspaceXML = Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace));
      onChange(newWorkspaceXML);
    };

    workspace.addChangeListener(handleChange);

    workspaceRef.current = workspace;

    // Clean up
    return () => {
      workspace.removeChangeListener(handleChange);
      workspace.dispose();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    get workspace() {
      return workspaceRef.current;
    }
  }));

  return (
    <div ref={blocklyDivRef} style={{ height: '100vh', width: '100%' }} />
  );
});

export default BlocklyComponent;
