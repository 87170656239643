import React, { useContext, useState } from 'react';
import { Avatar, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import PaymentContext from './paymentContext';
import { authMethods } from '../firebase/authmethods'; // Import your authentication methods (e.g., for logging out)

// ProfileMenu Component
const ProfileMenu = () => {
  const { user, paymentFlag, setUser, setPaymentFlag } = useContext(PaymentContext);  // Fetch user and payment status from context
  const [anchorEl, setAnchorEl] = useState(null);  // For controlling the menu anchor

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);  // Open the menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null);  // Close the menu
  };

  // Handle logout logic
  const handleLogout = () => {
    authMethods.logout(setUser, setPaymentFlag);  // Your logout method
    handleMenuClose();  // Close the menu after logging out
  };

  return (
    <div style={{ position: 'absolute', top: '10px', right: '10px' }}>  {/* Place at the top-right corner */}
      {/* Menu Button with Avatar */}
      <IconButton onClick={handleMenuOpen} size="large">
        <Avatar alt={user?.displayName || "User"} src={user?.photoURL} />  {/* Show user's profile picture */}
      </IconButton>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {/* User Information */}
        <MenuItem disabled>
          <Typography variant="body1">
            {user?.displayName || "Guest User"}
          </Typography>
        </MenuItem>

        {/* Payment Status */}
        <MenuItem disabled>
          <Typography variant="body2" color={paymentFlag ? "green" : "red"}>
            {paymentFlag ? "Payment: Completed" : "Payment: Pending"}
          </Typography>
        </MenuItem>

        {/* Logout Button */}
        <MenuItem onClick={handleLogout}>
          <Typography variant="body1" style={{ color: 'red' }}>
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileMenu;
