import React, { useContext, useState, useEffect } from 'react';
import PaymentContext from "./paymentContext";
import GoogleSignInButton from "./GoogleSignInButton";
import StripeBuyButton from "./StripeBuyButton";
import { useHistory, useLocation } from 'react-router-dom';  // Use useHistory instead of useNavigate

const PaymentCheck = () => {
  const { paymentFlag } = useContext(PaymentContext);
  const [returnUrl, setReturnUrl] = useState('');
  const history = useHistory();  // useHistory hook from React Router v5
  const location = useLocation();  // useLocation hook to get the current page URL

  useEffect(() => {
    // Store the referring page (URL) in the state when the component mounts
    const currentUrl = location.pathname + location.search;
    setReturnUrl(currentUrl);
  }, [location]);

  const handleGoBack = () => {
    if (returnUrl) {
      history.push(returnUrl);  // Use history.push to go back to the original page
    } else {
      history.push('/');  // Default to homepage if no URL found
    }
  };

  if (!paymentFlag) {
    return (
      <div style={{ 
        width: '400px', 
        margin: '0 auto', 
        marginTop: '50px', 
        textAlign: 'center', 
        color: '#E0E0E0', 
        fontFamily: 'Roboto, Arial, sans-serif' 
      }}>
        <div style={{ marginBottom: '20px' }}>
          <h2 style={{ color: '#B0B0B0', fontFamily: 'Roboto, Arial, sans-serif' }}>
            Step 1: Sign in with Google
          </h2>
          <GoogleSignInButton />
        </div>
        <br/>
        <br/>
        <div>
          <h2 style={{ color: '#B0B0B0', fontFamily: 'Roboto, Arial, sans-serif' }}>
            Step 2: Complete your subscription
          </h2>
          <br/>
        <br/>
          <StripeBuyButton />
        </div>

        {/* Add a "Go back" button */}
        <div style={{ marginTop: '20px' }}>
          <button 
            onClick={handleGoBack}
            style={{
              padding: '10px 20px',
              backgroundColor: '#4CAF50',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Back to Previous Page
          </button>
        </div>
      </div>
    );
  }

  return null;
};

export default PaymentCheck;
