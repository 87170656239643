import React from 'react';

const ConversationBlock = ({ speaker, avatarUrl, title, children, position = 'left' }) => {
  const isRightAligned = position === 'right';
  
  return (
    <div style={{
      display: 'flex',
      justifyContent: isRightAligned ? 'space-between' : 'flex-start',
      marginBottom: '20px',
      padding: '20px',
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      backgroundColor: '#fff',
      maxWidth: '600px',
      margin: '20px auto',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
    }}>
      {/* Conditional rendering for left or right alignment */}
      {position === 'left' && (
        <div style={{ marginRight: '10px' }}>
          <img src={avatarUrl} alt={`${speaker} avatar`} style={{ borderRadius: '50%', width: '80px', height: '80px' }} />
        </div>
      )}
      <div style={{ flex: 1 }}>
        <div style={{ textAlign: isRightAligned ? 'right' : 'left' }}>
          <h2 style={{ margin: '0 0 10px 0', fontSize: '1.5rem', color: '#333' }}>{title}</h2>
        </div>
        <div style={{ textAlign: isRightAligned ? 'right' : 'left', marginTop: '20px' }}>
          {children}
        </div>
      </div>
      {position === 'right' && (
        <div style={{ marginLeft: '10px' }}>
          <img src={avatarUrl} alt={`${speaker} avatar`} style={{ borderRadius: '50%', width: '80px', height: '80px' }} />
        </div>
      )}
    </div>
  );
};

export default ConversationBlock;
