import { useState } from "react";

import SimpleModal from "./SimpleModal"
import { DialogContent, DialogTitle, IconButton, Box } from '@material-ui/core';
import { Close as CloseIcon, Minimize as MinimizeIcon, Maximize as MaximizeIcon } from '@material-ui/icons';
import GetGGBJson from "./GetGGBJson";

import { findIndex, includes, map } from 'lodash';

const GeogebraDraw = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [index, setIndex] = useState(0);
  const data = props.data;

  function handleClick(d) {
    if(includes(map(data, 'button'),d)) {
      setIndex(findIndex(data, (e)=> e.button===d))
      setIsOpen(true);
    }
  }

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  }

  return (
    <>
     {isOpen && 
  <SimpleModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <div>
      <DialogContent /* remaining code */>
        {data[index].component}
      </DialogContent>
    </div>
  </SimpleModal>
}
<Box style={{ display: 'flex', justifyContent: 'center' }}>
  <GetGGBJson
    jsonUrl={props.jsonUrl}
    ggbParameters={props.ggbParameters}
    appId={props.appId}
    onClickHandle={handleClick}
  />
</Box>

    </>
  );
}

export default GeogebraDraw;
