import React, { useState, useRef, useEffect } from 'react';
import './ScrollableNav.css';

const ScrollableNav = ({ sections }) => {
  const [activeSection, setActiveSection] = useState(null);
  const contentRef = useRef(null);
  const offset = 100; // Adjust this value to control how much space above the element is shown

  const handleScrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement && contentRef.current) {
      const elementPosition = sectionElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      // Smooth scroll to the position with offset
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
    setActiveSection(sectionId);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const sections = contentRef.current.querySelectorAll('.section');
        sections.forEach((section) => {
          const { top } = section.getBoundingClientRect();
          if (top >= 0 && top <= 150) {
            setActiveSection(section.id);
          }
        });
      }
    };

    contentRef.current?.addEventListener('scroll', handleScroll);
    return () => contentRef.current?.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="scrollable-nav-container">
      {/* Sticky Navigation Panel */}
      <nav className="scrollable-nav">
        {sections
          .filter((section) => !section.skip) // Filter out sections with skip flag
          .map(({ id, label }) => (
            <button
              key={id}
              onClick={() => handleScrollToSection(id)}
              className={activeSection === id ? 'active' : ''}
            >
              {label}
            </button>
          ))}
      </nav>

      {/* Scrollable Content Sections */}
      <div className="main-content" ref={contentRef}>
        {sections.map(({ id, component: Component, useCardStyle }) => (
          <div
            key={id}
            id={id}
            className={`${useCardStyle !== false ? 'section' : ''} ${activeSection === id ? 'active' : ''}`}
          >
            <Component />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScrollableNav;
