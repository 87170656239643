import React, { useState } from 'react';

const ImageViewer = ({ image, annotations }) => {
  const [activeAnnotation, setActiveAnnotation] = useState(null);

  // Function to handle clicking on an annotation
  const handleAnnotationClick = (annotation) => {
    setActiveAnnotation(annotation);
  };

  return (
    <div style={{ maxWidth: '80%', margin: '0 auto', position: 'relative' }}>
      <h2 style={{ textAlign: 'center' }}>Image Viewer</h2>
      <div style={{ position: 'relative' }}>
        <img src={image} alt="Annotated" style={{ width: '100%' }} />
        
        {/* Render the annotations */}
        {annotations.map((annotation) => (
          <div
            key={annotation.id}
            style={{
              position: 'absolute',
              left: `${annotation.x}px`,
              top: `${annotation.y}px`,
              border: '2px solid #007bff',
              backgroundColor: 'rgba(0, 123, 255, 0.5)',
              color: '#fff',
              padding: '5px',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            onClick={() => handleAnnotationClick(annotation)}
          >
            Annotation
          </div>
        ))}
      </div>

      {/* Render the active annotation details as a popup */}
      {activeAnnotation && (
        <div style={popupStyle}>
          <p>{activeAnnotation.text}</p>
          {/* Display video if the text is a video URL */}
          {activeAnnotation.text.includes('http') && (
            <iframe
              width="300"
              height="200"
              src={activeAnnotation.text}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Annotation Video"
            ></iframe>
          )}
          <button onClick={() => setActiveAnnotation(null)} style={closeButtonStyle}>
            Close
          </button>
        </div>
      )}
    </div>
  );
};

// Popup styling
const popupStyle = {
  position: 'absolute',
  top: '50px',
  left: '50px',
  backgroundColor: 'white',
  border: '1px solid black',
  padding: '15px',
  borderRadius: '8px',
  zIndex: 1000,
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
};

// Close button styling
const closeButtonStyle = {
  marginTop: '10px',
  padding: '5px 10px',
  backgroundColor: '#dc3545',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
};

export default ImageViewer;
