// FloatingContactButton.jsx
import React, { useState, useEffect, useRef } from 'react';
import { FiMail } from 'react-icons/fi'; // Import the contact icon from React Icons

const FloatingContactButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);
  const buttonRef = useRef(null);

  // Close the popup when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (isOpen) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [isOpen]);

  return (
    <>
      {/* Floating Contact Button */}
      <button
        ref={buttonRef}
        style={styles.contactButton}
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Contact Us"
      >
        <FiMail style={styles.icon} />
      </button>

      {/* Popup Container */}
      {isOpen && (
        <div ref={popupRef} style={styles.contactPopup}>
          <div style={styles.contactPopupContent}>
            <span
              style={styles.closePopup}
              onClick={() => setIsOpen(false)}
            >
              &times;
            </span>
            <h3 style={styles.heading}>Contact Us</h3>
            <p>
              <a href="mailto:office@perceptron.solutions" style={styles.mailLink}>
                Email: office@perceptron.solutions
              </a>
            </p>
            {/* Google Calendar Appointment Scheduling begin */}
            <iframe
              src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2x8pvOth-b3pozTeKWIuxYeY8CD-gUAmZUyjU2PnoPFZL4QygaXY6xUU6Mx-q1lhwHgKqpJP0w?gv=true"
              style={styles.iframe}
              frameBorder="0"
              allowFullScreen
              title="Google Calendar Appointment Scheduling"
            ></iframe>
            {/* end Google Calendar Appointment Scheduling */}
          </div>
        </div>
      )}
    </>
  );
};

// Inline styles
const styles = {
  contactButton: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    cursor: 'pointer',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: '28px', // Adjust icon size as needed
  },
  contactPopup: {
    position: 'fixed',
    bottom: '100px',
    right: '20px',
    width: '320px',
    maxHeight: '80vh',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: '10px',
    overflowY: 'auto',
    boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
    zIndex: 999,
  },
  contactPopupContent: {
    padding: '20px',
    position: 'relative',
  },
  closePopup: {
    position: 'absolute',
    top: '5px',
    right: '10px',
    fontSize: '25px',
    color: '#aaa',
    cursor: 'pointer',
  },
  heading: {
    marginTop: '0',
    fontSize: '1.2em',
  },
  mailLink: {
    color: '#007BFF',
    textDecoration: 'none',
  },
  iframe: {
    border: '0',
    width: '100%',
    height: '400px',
  },
};

export default FloatingContactButton;
