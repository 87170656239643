import React, { useState, useEffect, useRef } from 'react';
import GeogebraDraw from './GeogebraDraw';
import GeogebraCommandButton from './GeogebraCommandButton';
import {
  Drawer,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const GeoGebraWithMenu = ({
  jsonUrl,
  appId,
  email,
  ggbParameters,
  menuSections,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);
  const [isGgbInView, setIsGgbInView] = useState(false);
  const ggbContainerRef = useRef(null);

  const toggleMenu = () => setShowMenu(!showMenu);
  const toggleSection = (index) => {
    setExpandedSection(expandedSection === index ? null : index);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const isIntersecting = entry.isIntersecting;
        setIsGgbInView(isIntersecting);
        setShowMenu(isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (ggbContainerRef.current) {
      observer.observe(ggbContainerRef.current);
    }

    return () => {
      if (ggbContainerRef.current) {
        observer.unobserve(ggbContainerRef.current);
      }
    };
  }, []);

  const containerStyle = {
    display: 'flex',
    width: '100%',
    position: 'relative',
  };

  const ggbStyle = {
    flex: 1,
  };

  const menuButtonStyle = {
    position: 'absolute',
    top: '20px',
    right: showMenu ? '300px' : '20px',
    zIndex: 1300,
    display: isGgbInView ? 'block' : 'none',
  };

  return (
    <div style={containerStyle}>
      <div ref={ggbContainerRef} style={ggbStyle}>
        <GeogebraDraw
          jsonUrl={jsonUrl}
          ggbParameters={ggbParameters}
          appId={appId}
          email={email}
          data={[]}
        />
      </div>

      <Drawer
        anchor="right"
        open={showMenu}
        onClose={toggleMenu}
        variant="persistent"
        PaperProps={{
          style: { width: '300px', padding: '20px' },
        }}
      >
        <IconButton
          onClick={toggleMenu}
          style={{ marginBottom: '20px', alignSelf: 'flex-end' }}
        >
          <CloseIcon />
        </IconButton>

        {menuSections.map((section, index) => (
          <Accordion
            key={index}
            expanded={expandedSection === index}
            onChange={() => toggleSection(index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="h6">{section.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {section.commands.map((command, cmdIndex) => (
                  <Box mb={2} key={cmdIndex}>
                    <GeogebraCommandButton
                      command={command.command}
                      label={command.label}
                    />
                  </Box>
                ))}
                {section.text && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    {section.text}
                  </Typography>
                )}
                {section.videoUrl && (
                  <video
                    controls
                    style={{
                      width: '100%',
                      marginTop: '10px',
                      borderRadius: '8px',
                    }}
                  >
                    <source src={section.videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </Drawer>

      <Button
        variant="contained"
        color="primary"
        onClick={toggleMenu}
        style={menuButtonStyle}
      >
        {showMenu ? 'Close Menu' : 'Open Menu'}
      </Button>
    </div>
  );
};

export default GeoGebraWithMenu;