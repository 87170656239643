import { useState } from 'react';

const ToggleContent = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <button onClick={handleToggle}>
        {isVisible ? 'Hide Details' : 'See Details'}
      </button>
      {isVisible && <div>{children}</div>}
    </div>
  );
};

export default ToggleContent;
