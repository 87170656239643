import React, { useRef, useEffect, useImperativeHandle } from 'react';
import Blockly from 'blockly/core'; // Correct import for blockly

import DarkTheme from '@blockly/theme-dark';



Blockly.Blocks['network_type_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Model Complexity")
        .appendField(new Blockly.FieldDropdown([["Simple", "MLP"], ["Medium", "RNN"], ["Complex", "CNN"]]), "network_type");
    this.appendDummyInput()
        .appendField("Signal Type")
        .appendField(new Blockly.FieldDropdown([["Bumpy", "ReLU"], ["Smooth", "Sigmoid"], ["Fast", "Tanh"], ["Slow", "Softmax"]]), "activation_function");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(20); 
    this.setTooltip("This block lets you choose the complexity of your model and the type of signal it uses to make decisions.");
  }
};

Blockly.Blocks['input_data_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Original Data");
    this.setNextStatement(true, null);
    this.setPreviousStatement(true, null);
    this.setColour(20);
    this.setTooltip("This block represents the original data to the model.");
  } 
};



Blockly.Blocks['gen_data'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Generated Data");
    this.setNextStatement(true, null);
    this.setPreviousStatement(true, null);
    this.setColour(120); // Set the block color to green.
    // Update the tooltip content here
    this.setTooltip("This block represents data generated by the system.");
  }
};



Blockly.Blocks['ground_truth_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Expected Data");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(10);
    this.setTooltip("This block represents the expected data that the model should generate.");
  }
};

Blockly.Blocks['encoder_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Data Compressor");
    this.appendStatementInput("OPTIONS").setCheck(['NetworkType']);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(60);
    this.setTooltip("This block compresses the original data into a simpler form.");
  }
};

Blockly.Blocks['decoder_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Data Decompressor");
    this.appendStatementInput("OPTIONS").setCheck(['NetworkType']);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(260);
    this.setTooltip("This block decompresses the simplified data back into its original form.");
  }
};

Blockly.Blocks['latent_space_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Simplified Data");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(60);
    this.setTooltip("This block represents the simplified data form after being compressed.");
  }
};



Blockly.Blocks['training_loop_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Learning Process");
    this.appendDummyInput()
        .appendField("Measure of Error")
        .appendField(new Blockly.FieldDropdown([["Small difference", "MSE"], ["Large difference", "CrossEntropy"], ["Average difference", "LogLoss"]]), "loss_type");
    this.appendDummyInput()
        .appendField("Learning Speed")
        .appendField(new Blockly.FieldDropdown([["Slow", "SGD"], ["Moderate", "Adam"], ["Fast", "RMSprop"]]), "optimizer");
    this.appendDummyInput()
        .appendField("Learning Duration")
        .appendField(new Blockly.FieldNumber(10), "epochs");
    this.appendDummyInput()
        .appendField("Loss");
    this.appendStatementInput("GENERATED_DATA") // Updated to GENERATED_DATA
        .appendField("Generated Data")
        .setCheck(['gen_data']); // Set to check for 'gen_data' blocks
    this.appendStatementInput("GROUND_TRUTH")
        .appendField("Expected Data")
        .setCheck(['ground_truth_b']);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("This block configures the learning process of the model.");
  }
};


Blockly.Blocks['horizontal_input'] = {
  init: function() {
    this.appendValueInput('INPUT1');
    this.appendValueInput('INPUT2');
    this.appendValueInput('INPUT3');
    this.appendValueInput('INPUT4');
    this.setInputsInline(true);
    this.setColour(230);
    this.setTooltip("This is a block with four horizontal inputs.");
  }
};



Blockly.Blocks['empty_input_b'] = {
  init: function() {
    this.appendDummyInput();
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("This is an empty block that can be used as a connection point.");
  }
};


Blockly.Blocks['model_save_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Remember Model");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(80);
    this.setTooltip("This block saves the trained model so you can use it again later.");
  }
};

Blockly.Blocks['model_load_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Recall Model");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(80);
    this.setTooltip("This block loads a previously saved model.");
  }
};

Blockly.Blocks['train_step_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Training Step");
    this.setPreviousStatement(true, "TrainStep");
    this.setNextStatement(true, "TrainStep");
    this.setColour(60);
    this.setTooltip("This block represents a single step in the training process.");
  }
};


Blockly.Blocks['model_b'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("Model");
    this.appendStatementInput("OPTIONS").setCheck(['NetworkType']);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("This block represents the model. You can specify the structure of the model here.");
  }
};

Blockly.Blocks['large_block'] = {
  init: function() {
    this.appendDummyInput()
        .appendField("                  "); // Fill up with space
    this.appendDummyInput()
        .appendField("                  "); // Fill up with space
    this.appendDummyInput()
        .appendField("                  "); // Fill up with space
    this.appendDummyInput()
        .appendField("                  "); // Fill up with space
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setInputsInline(true);
    this.setColour(230);
    this.setTooltip("This is a large block.");
  }
};



const VAEComponentBeginner = React.forwardRef(({ workspaceXML, onChange }, ref) => {
  const workspaceRef = useRef(null);
  const blocklyDivRef = useRef(null);

  const toolboxXML = `
  <xml xmlns="http://www.w3.org/1999/xhtml" id="toolbox" style="display: none;">
    <block type="latent_space_b"></block>
    <block type="ground_truth_b"></block>
    <block type="input_data_b"></block>
    <block type="network_type_b"></block>
    <block type="decoder_b"></block>
    <block type="gen_data"></block> <!-- Updated to gen_data -->
    <block type="training_loop_b"></block>
    <block type="train_step_b"></block>
    <block type="model_save_b"></block>
    <block type="model_load_b"></block>
  </xml>
`;

  useEffect(() => {

    const workspace = Blockly.inject(blocklyDivRef.current, {
   toolbox: toolboxXML,
      scrollbars: false,
      theme: DarkTheme,  // Use the imported dark theme
      toolboxPosition: 'left'
    });

    if (!workspaceXML) {
      // Create a new default block of type 'network_type_b' and add it to the workspace
      const defaultBlock = workspace.newBlock('model_b');
      // Move the block to a specific position
      defaultBlock.moveBy(300, 300);
      // Initialize block and render it
      defaultBlock.initSvg();
      defaultBlock.render();

      const defaultBlock2 = workspace.newBlock('encoder_b');
      // Move the block to a specific position
      defaultBlock2.moveBy(400, 300);
      // Initialize block and render it
      defaultBlock2.initSvg();
      defaultBlock2.render();

      const defaultBlock3 = workspace.newBlock('decoder_b');
      // Move the block to a specific position
      defaultBlock3.moveBy(500, 400);
      // Initialize block and render it
      defaultBlock3.initSvg();
      defaultBlock3.render();




    } else {
      Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(workspaceXML), workspace);
    }

    const handleChange = (event) => {
      if (event.type === Blockly.Events.UI) {
        return;
      }

      const newWorkspaceXML = Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace));
      onChange(newWorkspaceXML);
    };

    workspace.addChangeListener(handleChange);

    workspaceRef.current = workspace;

    // Clean up
    return () => {
      workspace.removeChangeListener(handleChange);
      workspace.dispose();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    get workspace() {
      return workspaceRef.current;
    }
  }));

  return (
    <div ref={blocklyDivRef} style={{ height: '100vh', width: '100%' }} />
  );
});

export default VAEComponentBeginner;
