// QuizRadioButtons.jsx
import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import './QuizComponent.css';

const QuizRadioButtons = ({ categoryName, questions }) => {
  const [responses, setResponses] = useState({});
  const [score, setScore] = useState(0);
  const [expandedPanel, setExpandedPanel] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const [mediaContent, setMediaContent] = useState(null);

  // Debug: useEffect to log changes in responses and score
  useEffect(() => {
    console.log('Responses:', responses);
    console.log('Score:', score);
  }, [responses, score]);

  const handleOptionChange = (questionId, selectedOption) => {
    const updatedResponses = {
      ...responses,
      [questionId]: selectedOption,
    };
    setResponses(updatedResponses);

    // Calculate score based on updated responses
    const newScore = questions.reduce((acc, question) => {
      return acc + (updatedResponses[question.id] === question.correctAnswer ? 1 : 0);
    }, 0);
    setScore(newScore);
  };

  const handleAccordionChange = (panel, questionMediaContent) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
    if (questionMediaContent) {
      setMediaContent(questionMediaContent);
      setShowMedia(isExpanded);
    } else {
      setShowMedia(false);
    }
  };

  return (
    <>
      <div className="quiz-container">
        <div className="score-panel">
          <h2>
            {categoryName} (Score: {score}/{questions.length})
          </h2>
        </div>
        {questions.map((question) => (
          <Accordion
            key={question.id}
            expanded={expandedPanel === question.id}
            onChange={handleAccordionChange(question.id, question.mediaContent)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${question.id}-content`}
              id={`panel${question.id}-header`}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                {question.text}
              </Typography>
              {question.mediaContent && (
                <IconButton
                  aria-label="Toggle Media"
                  onClick={(e) => {
                    e.stopPropagation();
                    setMediaContent(question.mediaContent);
                    setShowMedia((prev) => !prev);
                  }}
                >
                  <PlayCircleOutlineIcon />
                </IconButton>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div className="options-container">
                {question.options.map((option) => (
                  <label key={option.key} className="option-label">
                    <input
                      type="radio"
                      name={`${categoryName}-${question.id}`}
                      checked={responses[question.id] === option.key}
                      onChange={() => handleOptionChange(question.id, option.key)}
                    />
                    {option.text}
                  </label>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

      {mediaContent && showMedia && (
        <Paper
          elevation={4}
          sx={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            width: '300px',
            maxWidth: '80%',
            zIndex: 1000,
            padding: '16px',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="subtitle1">Media Content</Typography>
            <IconButton size="small" onClick={() => setShowMedia(false)}>
              ✕
            </IconButton>
          </div>
          <div style={{ marginTop: '8px' }}>{mediaContent}</div>
        </Paper>
      )}
    </>
  );
};

export default QuizRadioButtons;
