// // CodeAccordion.jsx
// import React from 'react';
// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Typography,
//   useTheme,
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
// // Import the languages you need
// import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
// // Import a syntax highlighting theme
// import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';

// // Register the language
// SyntaxHighlighter.registerLanguage('python', python);

// const CodeAccordion = ({ children, language = 'python', title }) => {
//   const theme = useTheme();

//   return (
//     <Accordion
//       sx={{
//         borderRadius: '8px',
//         marginBottom: '16px',
//         '&:before': {
//           display: 'none',
//         },
//         boxShadow: 'none',
//         backgroundColor: theme.palette.background.default,
//       }}
//     >
//       <AccordionSummary
//         expandIcon={<ExpandMoreIcon />}
//         aria-controls={`${title}-content`}
//         id={`${title}-header`}
//         sx={{
//           backgroundColor: theme.palette.action.hover,
//           borderRadius: '8px',
//           minHeight: '48px',
//           '& .MuiAccordionSummary-content': {
//             margin: 0,
//           },
//         }}
//       >
//         <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
//           {title}
//         </Typography>
//       </AccordionSummary>
//       <AccordionDetails
//         sx={{
//           backgroundColor: theme.palette.background.paper,
//           maxHeight: '600px', // Optional: set max height
//           overflowY: 'auto', // Optional: enable scrolling if content exceeds max height
//         }}
//       >
//         <SyntaxHighlighter
//           language={language}
//           style={github}
//           customStyle={{ backgroundColor: 'inherit', margin: 0 }}
//           showLineNumbers
//         >
//           {children}
//         </SyntaxHighlighter>
//       </AccordionDetails>
//     </Accordion>
//   );
// };

// export default CodeAccordion;



// CodeAccordion.jsx
// CodeAccordion.jsx
// CodeAccordion.jsx
import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useTheme,
  IconButton,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';

// Register the language
SyntaxHighlighter.registerLanguage('python', python);

const CodeAccordion = ({
  children,
  language = 'python',
  title,
  mediaContent, // Media content to display
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [showMedia, setShowMedia] = useState(false);

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (mediaContent) {
      setShowMedia(isExpanded);
    }
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        sx={{
          borderRadius: '8px',
          marginBottom: '16px',
          '&:before': {
            display: 'none',
          },
          boxShadow: 'none',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${title}-content`}
          id={`${title}-header`}
          sx={{
            backgroundColor: theme.palette.action.hover,
            borderRadius: '8px',
            minHeight: '48px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg)',
            },
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', flexGrow: 1 }}>
            {title}
          </Typography>
          {mediaContent && (
            <IconButton
              aria-label="Toggle Media"
              onClick={(e) => {
                e.stopPropagation();
                setShowMedia(!showMedia);
              }}
            >
              <PlayCircleOutlineIcon />
            </IconButton>
          )}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: theme.palette.background.paper,
            maxHeight: '600px', // Optional: set max height
            overflowY: 'auto', // Optional: enable scrolling if content exceeds max height
          }}
        >
          <SyntaxHighlighter
            language={language}
            style={github}
            customStyle={{ backgroundColor: 'inherit', margin: 0 }}
            showLineNumbers
          >
            {children}
          </SyntaxHighlighter>
        </AccordionDetails>
      </Accordion>

      {mediaContent && showMedia && (
        <Paper
          elevation={10}
          sx={{
            position: 'fixed',
            top: '20px',     // Changed from 'bottom' to 'top'
            right: '20px',
            width: '300px',
            maxWidth: '80%',
            zIndex: 100000000,
            padding: '16px',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="subtitle1">{title}</Typography>
            <IconButton
              size="small"
              onClick={() => setShowMedia(false)}
            >
              ✕
            </IconButton>
          </div>
          <div style={{ marginTop: '8px' }}>{mediaContent}</div>
        </Paper>
      )}
    </>
  );
};

export default CodeAccordion;

