import React from 'react';

const GeogebraCommandButton = ({ command, label }) => {
  const handleClick = () => {
    if (window.ggbApplet) {
      window.ggbApplet.evalCommand(command);
    } else {
      console.error("GeoGebra applet is not loaded yet.");
    }
  };

  return (
    <button onClick={handleClick}>
      {label}
    </button>
  );
};

export default GeogebraCommandButton;
