/**
  This CodeSandbox has been automatically generated using
  `codesandboxer`. If you're curious how that happened, you can
  check out our docs here: https://github.com/codesandbox/codesandboxer

  If you experience any struggles with this sandbox, please raise an issue
  on github. :)
*/
import React from "react";
import ReactDOM from "react-dom";
import MathApp from "./mathApp";
import BlogsApp from "./blogsApp";
import AuthProvider from "./provider/AuthProvider";
import Howler from 'react-howler';

import { BrowserRouter as Router } from 'react-router-dom';

import PaymentProvider from './components/paymentProvider';



import {MDXProvider} from '@mdx-js/react'
import CodeBlock from './markdown-styles/CodeBlock'
import Header1 from './markdown-styles/Header1'
import Header3 from './markdown-styles/Header3'
import Header2 from './markdown-styles/Header2'
import Paragraph from './markdown-styles/Paragraph'
import Link_ from './markdown-styles/Link_'
import Thing from './markdown-styles/Sections'
import AppHeader from "./components/AppHeader";
import GenAIAppHeader from "./components/GenAIAppHeader";


import MyCarousel from "./components/MyCarousel";
import ButtonMailto from "./components/ButtonMailto.js";
import SimpleDialog from "./components/SimpleDialog.js";
import Quiz from "./components/Quiz.js";
import LandmarkButton from './components/LandmarkButton';
import MyAudioPlayer from './components/layouts/AudioPlayer';
import ReactPlayer from 'react-player'
import VideoPlayer from './components/VideoPlayer';
import Marker from './components/ImageMarkers';
import MarkerDialog from './components/ImageMarkersFullScreen';

import FullScreenDialog from './components/DialogComponent'

import ImageAnnotation from "./components/ImageAnnotation";
import Container from "./components/layouts/Container";
import GeogebraDraw from "./components/GeogebraDraw";
import ImageCard from './components/ImageCard'

import FullWidthImageCard from './components/FullWidthImageCard'
import BlinkingButton from './components/BlinkingButton';
import SimpleAccordion from './components/SimpleAccordian'
import ImgMediaCard from "./components/ImgMediaCard";
import VideoMediaCard from "./components/VideoMediaCard";
import Header from "./components/Header";
import HowlerRead from "./components/HowlerRead";
import LearningGoals from "./components/LearningGoals";
import TitleSection from "./components/TitleSection";
import SubtitleSection from "./components/SubtitleSection";
import SomeComponent from "./components/SomeComponent";
import QuizSlide from "./components/QuizSlide";
import MySlider from "./components/common/MySlider";
import AIBanner from "./components/AIBanner";
import TwoPartRow from "./components/TwoPartRow";
import CustomHeader from "./components/CustomHeader";
import RedirectButton from "./components/RedirectButton";
import QuizRadioButtons from "./components/QuizRadioButtons";
import FullScreenVideo from "./components/FullScreenVideo";
import ExpandableList  from "./components/ExpandableList";
import TableOfContents  from "./components/TableOfContents";
import AboutSection  from "./components/AboutSection";
import CodeComponent  from "./components/CodeComponent";
import LinkedInEmbed from  "./components/LinkedInEmbed";
import ScrollableNav from './components/ScrollableNav';
import ToggleContent from './components/ToggleContent';
import ConversationBlock from './components/ConversationBlock'

import ImageAnnotator from './components/ImageAnnotator'
import ImageViewer from './components/ImageViewer'
import  CodeAccordion from './components/CodeAccordion'
import  GenericAccordion from './components/GenericAccordion'
import GeogebraCommandButton from './components/GeogebraCommandButton'
import FloatingContactButton from './components/common/FloatingContactButton'
import GeoGebraWithMenu from './components/GeogebraWithMenu'
import ProfileMenu  from './components/ProfileMenu'


import Questionnaire from './components/Questionnaire'

import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import 'katex/dist/katex.min.css'; // Import KaTeX CSS here

const components = {
    h1:  Header1,
    h2:  Header2,
    h3:  Header3,
    p:   Paragraph,
    a:   Link_,
    FloatingContactButton,
    GeogebraCommandButton,
    ProfileMenu,
    GeoGebraWithMenu,
    CodeAccordion,
    ScrollableNav,
    ImageViewer,
    ImageAnnotator,
    FullScreenDialog,
    ToggleContent,
    ConversationBlock,
    GenericAccordion,
    Howler,
    LinkedInEmbed,
    AIBanner,
    CustomHeader,
    TwoPartRow,
    MarkerDialog,
    SomeComponent,
    TitleSection,
    SubtitleSection,
    Questionnaire,
    Header,
    HowlerRead,
    ReactPlayer,
    pre: CodeBlock,
    code: CodeBlock,
    thing: Thing,
    MyCarousel,
    ButtonMailto,
    SimpleDialog,
    Quiz,
    LandmarkButton,
    MyAudioPlayer,
    ReactPlayer,
    VideoPlayer,
    ImageCard,
    Marker,
    ImageAnnotation,
    Container,
    GeogebraDraw,
    AppHeader,
    GenAIAppHeader,
    BlinkingButton,
    SimpleAccordion,
    ImgMediaCard,
    FullWidthImageCard,
    VideoMediaCard,
    LearningGoals,
    QuizSlide,
    MySlider,
    RedirectButton,
    QuizRadioButtons,
    FullScreenVideo,
    ExpandableList,
    TableOfContents,
    AboutSection,
    wrapper: ({ ...props}) => (
      <div style={{padding: '50px', backgroundColor: 'cream'}}>
        <main {...props} />
      </div>
    )

  //  code: props => <pre style={{color: 'tomato'}} {...props} />
}

const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
  spacing: 4
});




ReactDOM.render(
  <ThemeProvider theme={darkTheme}>
      <PaymentProvider>
    <MDXProvider components={components}>
      {/* <AuthProvider> */}
          <Router>
          <BlogsApp/>
          </Router>
      {/* </AuthProvider> */}
    </MDXProvider>
    </PaymentProvider>
  </ThemeProvider>,
  document.getElementById('root')
);