import React, { useState, useRef, useEffect } from 'react';
import Draggable from 'react-draggable';

const ImageAnnotator = ({ image }) => {
  const [annotations, setAnnotations] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [newAnnotation, setNewAnnotation] = useState(null);
  const [annotationText, setAnnotationText] = useState('');
  const [activeAnnotation, setActiveAnnotation] = useState(null);
  const imageRef = useRef(null);
  const containerRef = useRef(null);
  const fileInputRef = useRef(null);

  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  // Set container size based on the image's natural dimensions
  useEffect(() => {
    if (imageRef.current) {
      const updateContainerSize = () => {
        const { naturalWidth, naturalHeight } = imageRef.current;
        setContainerSize({ width: naturalWidth, height: naturalHeight });
      };
      updateContainerSize();
      window.addEventListener('resize', updateContainerSize);
      return () => window.removeEventListener('resize', updateContainerSize);
    }
  }, [imageRef]);

  // Function to initiate adding an annotation at a random position within the image
  const startAddAnnotation = () => {
    if (!imageRef.current) return;
    const randomX = Math.random() * containerSize.width * 0.8; // Random X position within 80% of the image width
    const randomY = Math.random() * containerSize.height * 0.8; // Random Y position within 80% of the image height
    setNewAnnotation({ id: Date.now(), x: randomX, y: randomY, text: '' });
    setIsAdding(true);
  };

  // Confirm and save the annotation
  const confirmAnnotation = () => {
    if (annotationText.trim()) {
      const confirmedAnnotation = { ...newAnnotation, text: annotationText };
      setAnnotations([...annotations, confirmedAnnotation]);
    }
    setIsAdding(false);
    setAnnotationText('');
  };

  // Cancel adding an annotation
  const cancelAnnotation = () => {
    setNewAnnotation(null);
    setIsAdding(false);
  };

  // Update position when dragged
  const updateAnnotationPosition = (id, newX, newY) => {
    setAnnotations((prevAnnotations) =>
      prevAnnotations.map((annotation) =>
        annotation.id === id ? { ...annotation, x: newX, y: newY } : annotation
      )
    );
  };

  // Save annotations as a JSON file
  const saveAnnotations = () => {
    const dataStr = JSON.stringify(annotations);
    const blob = new Blob([dataStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'annotations.json';
    link.click();
  };

  // Load annotations from JSON file
  const loadAnnotations = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const loadedAnnotations = JSON.parse(e.target.result);
      setAnnotations(loadedAnnotations);
    };

    reader.readAsText(file);
  };

  return (
    <div style={{ maxWidth: '80%', margin: '0 auto' }}>
      <h2 style={{ textAlign: 'center' }}>Image Annotator</h2>

      <div
        ref={containerRef}
        style={{
          position: 'relative',
          width: `${containerSize.width}px`,
          height: `${containerSize.height}px`,
          margin: '0 auto',
          border: '1px solid #ccc',
          boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
        }}
      >
        <img
          ref={imageRef}
          src={image}
          alt="Annotatable"
          style={{ width: '100%', height: 'auto', display: 'block' }}
          onClick={startAddAnnotation}
        />

        {/* Render existing annotations as draggable */}
        {annotations.map((annotation) => (
          <Draggable
            key={annotation.id}
            bounds="parent"
            defaultPosition={{ x: annotation.x, y: annotation.y }}
            onStop={(e, data) => updateAnnotationPosition(annotation.id, data.x, data.y)}
          >
            <div
              style={{
                position: 'absolute',
                border: '1px solid #007bff',
                padding: '5px',
                backgroundColor: '#007bff',
                color: '#fff',
                borderRadius: '4px',
                cursor: 'move',
                zIndex: 10,
              }}
              onDoubleClick={() => {
                setNewAnnotation(annotation);
                setIsAdding(true);
                setAnnotationText(annotation.text);
              }}
              onClick={() => setActiveAnnotation(annotation)}
            >
              {annotation.text || 'Annotation'}
            </div>
          </Draggable>
        ))}

        {/* Show the new annotation if it's being added */}
        {newAnnotation && (
          <Draggable bounds="parent">
            <div
              style={{
                position: 'absolute',
                left: `${newAnnotation.x}px`,
                top: `${newAnnotation.y}px`,
                border: '1px solid #007bff',
                padding: '5px',
                backgroundColor: '#007bff',
                color: '#fff',
                borderRadius: '4px',
                cursor: 'move',
                zIndex: 10,
              }}
            >
              {annotationText || 'New Annotation'}
            </div>
          </Draggable>
        )}
      </div>

      {isAdding && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <h3>Add/Edit Annotation</h3>
            <textarea
              value={annotationText}
              onChange={(e) => setAnnotationText(e.target.value)}
              placeholder="Enter annotation text or video URL"
              style={{ width: '100%', height: '60px', marginBottom: '10px' }}
            />
            <button onClick={confirmAnnotation} style={buttonStyle}>
              Confirm
            </button>
            <button onClick={cancelAnnotation} style={{ ...buttonStyle, backgroundColor: '#dc3545' }}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Render the active annotation details as a popup */}
      {activeAnnotation && (
        <div style={popupStyle}>
          <p>{activeAnnotation.text}</p>
          {/* Display video if the text is a video URL */}
          {activeAnnotation.text.includes('http') && (
            <iframe
              width="300"
              height="200"
              src={activeAnnotation.text}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Annotation Video"
            ></iframe>
          )}
          <button onClick={() => setActiveAnnotation(null)} style={closeButtonStyle}>
            Close
          </button>
        </div>
      )}

      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <button onClick={saveAnnotations} style={buttonStyle}>
          Save Annotations
        </button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={loadAnnotations}
        />
        <button onClick={() => fileInputRef.current.click()} style={buttonStyle}>
          Load Annotations
        </button>
      </div>
    </div>
  );
};

// Modal styles
const modalStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const modalContentStyle = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  width: '400px',
  textAlign: 'center',
};

const popupStyle = {
  position: 'absolute',
  top: '50px',
  left: '50px',
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '15px',
  boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
};

const buttonStyle = {
  padding: '10px 20px',
  margin: '5px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

const closeButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#dc3545'
};

export default ImageAnnotator;
