// import React, { useState } from 'react';
// import { Button, Grid, Card, CardContent, Typography } from '@mui/material';

// const personas = [
//   {
//     name: "IT Nathan",
//     role: "IT Professional / Sysadmin",
//     currentSituation: "Does not have a background in Data Science, wants to upskill in AI.",
//     goal: "Learn AI tools and techniques.",
//     constraints: "Limited time, no prior knowledge of Data Science."
//   },
//   {
//     name: "Finance William",
//     role: "Portfolio Manager",
//     currentSituation: "Wants to build AI models relevant for finance. Background in Quant, upskilling to AI tools.",
//     goal: "Build custom models for finance applications.",
//     constraints: "Needs finance-specific AI knowledge."
//   },
//   {
//     name: "Excel Matthew",
//     role: "Quant Analyst",
//     currentSituation: "New to Python, has been modeling with Excel sheets. Wants to upgrade to Python, Data Science, and AI.",
//     goal: "Learn Python and Data Science techniques.",
//     constraints: "Needs structured learning to transition from Excel."
//   },
//   {
//     name: "Trader Evan",
//     role: "Trader",
//     currentSituation: "Knows AI and Python but doesn't have time to build models and debug.",
//     goal: "Get custom models built.",
//     constraints: "Time constraints, prefers pre-built solutions."
//   },
//   {
//     name: "CEO Steven",
//     role: "CEO",
//     currentSituation: "Wants to cut costs and improve productivity. Concerned about privacy and dependency on legacy models.",
//     goal: "Implement AI for quick experimentation and efficiency.",
//     constraints: "Needs fast, cost-effective AI solutions."
//   }
// ];

// const pathways = {
//   "IT Nathan": ["Learn AI Basics", "Hands-on AI Workshops"],
//   "Finance William": ["AI for Finance", "Custom Financial Models"],
//   "Excel Matthew": ["Python for Data Science", "Data Science Bootcamp"],
//   "Trader Evan": ["Custom AI Model Service", "Advanced AI Tools"],
//   "CEO Steven": ["AI for Business", "AI Cost-Saving Strategies"]
// };

// const Questionnaire = () => {
//   const [selectedPersona, setSelectedPersona] = useState(null);

//   const handlePersonaSelect = (persona) => {
//     setSelectedPersona(persona);
//   };

//   return (
//     <div className="questionnaire">
//       <Typography variant="h3" gutterBottom style={{ textAlign: 'center', marginBottom: '20px' }}>
//         Who are you? / Who do you relate to?
//       </Typography>

//       {/* Step 1: Show personas */}
//       <Grid container spacing={3}>
//         {personas.map((persona, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Card>
//               <CardContent>
//                 <Typography variant="h5" gutterBottom>{persona.name}</Typography>
//                 <Typography variant="body1"><strong>Role:</strong> {persona.role}</Typography>
//                 <Typography variant="body2"><strong>Current Situation:</strong> {persona.currentSituation}</Typography>
//                 <Typography variant="body2"><strong>Goal:</strong> {persona.goal}</Typography>
//                 <Typography variant="body2"><strong>Constraints:</strong> {persona.constraints}</Typography>
//                 <Button
//                   variant={selectedPersona === persona.name ? "contained" : "outlined"}
//                   color="primary"
//                   onClick={() => handlePersonaSelect(persona.name)}
//                   style={{ marginTop: '10px' }}
//                 >
//                   {selectedPersona === persona.name ? "Selected" : "This sounds like me"}
//                 </Button>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>

//       {/* Step 2: Show pathways based on selected persona */}
//       {selectedPersona && (
//         <div style={{ marginTop: '40px' }}>
//           <Typography variant="h4" gutterBottom>
//             Recommended Pathways for {selectedPersona}
//           </Typography>
//           <Grid container spacing={3}>
//             {pathways[selectedPersona].map((pathway, index) => (
//               <Grid item xs={12} sm={6} key={index}>
//                 <Card>
//                   <CardContent>
//                     <Typography variant="h6">{pathway}</Typography>
//                     <Button variant="contained" color="success" style={{ marginTop: '10px' }}>
//                       Explore
//                     </Button>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             ))}
//           </Grid>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Questionnaire;

// #working where we can ftch data from spreadsheet and see relevant cards

// import React, { useEffect, useState } from 'react';
// import { Grid, Card, CardContent, Typography, Button, CardMedia } from '@mui/material';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';


// // MainPage Component
// const MainPage = () => {
//   const [personas, setPersonas] = useState([]);
//   const [pathways, setPathways] = useState([]);
//   const [selectedPersona, setSelectedPersona] = useState(null);

//   // URLs for fetching data from Google Sheets
//   const personasSheetUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTKj7zd6AB8zvpDmBAnq3yrE6eEVgtMnzgl7Kc8SrolfQg2vAxlYWFXT0d1b1nW0Vtv1opNpNyh4Jbv/pub?output=tsv';
//   const pathwaysSheetUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQUGq6rQtC5i_d5mqNG935wGkDvwotVNx8CUWOD_X8Y5DFmjtpRbIbz8N601jyuroVyGhhubaDdelLl/pub?output=tsv';

//   useEffect(() => {
//     const fetchPersonas = async () => {
//       try {
//         const response = await fetch(personasSheetUrl);
//         const data = await response.text();
//         setPersonas(tsvToJson(data));
//       } catch (error) {
//         console.error('Error fetching personas:', error);
//       }
//     };

//     const fetchPathways = async () => {
//       try {
//         const response = await fetch(pathwaysSheetUrl);
//         const data = await response.text();
//         setPathways(tsvToJson(data));
//       } catch (error) {
//         console.error('Error fetching pathways:', error);
//       }
//     };

//     fetchPersonas();
//     fetchPathways();
//   }, [personasSheetUrl, pathwaysSheetUrl]);

//   // Convert TSV to JSON
//   const tsvToJson = (tsv) => {
//     const lines = tsv.split('\n');
//     const headers = lines[0].split('\t');
//     return lines.slice(1).map((line) => {
//       const values = line.split('\t');
//       const obj = {};
//       headers.forEach((header, index) => {
//         obj[header.trim()] = values[index] ? values[index].trim() : '';
//       });
//       return obj;
//     });
//   };

//   // Handle persona selection
//   const handlePersonaSelect = (personaName) => {
//     setSelectedPersona(personaName);
//   };

//   // Get pathways for selected persona
//   const getPathwaysForSelectedPersona = () => {
//     return pathways.filter((p) => p.personaName === selectedPersona);
//   };

//   // Check if the URL is a video by extension
//   const isVideo = (url) => {
//     return url && (url.endsWith('.mp4') || url.endsWith('.webm') || url.endsWith('.ogg'));
//   };

//   // Check if the URL is an image by extension
//   const isImage = (url) => {
//     return url && (url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.gif'));
//   };

//   return (
//     <div>
//       <Typography variant="h3" gutterBottom style={{ textAlign: 'center', marginBottom: '20px' }}>
//         Who are you? / Who do you relate to?
//       </Typography>

//       {/* Step 1: Show personas */}
//       <Grid container spacing={3}>
//         {personas.map((persona, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Card>
//               <CardContent>
//                 <Typography variant="h5" gutterBottom>{persona.name}</Typography>
//                 <Typography variant="body1"><strong>Role:</strong> {persona.role}</Typography>
//                 <Typography variant="body2"><strong>Current Situation:</strong> {persona.currentSituation}</Typography>
//                 <Typography variant="body2"><strong>Goal:</strong> {persona.goal}</Typography>
//                 <Typography variant="body2"><strong>Constraints:</strong> {persona.constraints}</Typography>
//                 <Button
//                   variant={selectedPersona === persona.name ? "contained" : "outlined"}
//                   color="primary"
//                   onClick={() => handlePersonaSelect(persona.name)}
//                   style={{ marginTop: '10px' }}
//                 >
//                   {selectedPersona === persona.name ? "Selected" : "This sounds like me"}
//                 </Button>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>

//       {/* Step 2: Show pathways based on selected persona */}
//       {selectedPersona && (
//   <div style={{ marginTop: '40px' }}>
//     <Typography variant="h4" gutterBottom>
//       Pathways for {selectedPersona}
//     </Typography>
//     <Grid container spacing={3}>
//       {getPathwaysForSelectedPersona().map((pathway, index) => (
//         <Grid item xs={12} sm={6} key={index}>
//           <Card>
//             {/* Show media only if the URL is not empty */}
//             {pathway.imageOrVideoUrl && (
//               <>
//                 {isImage(pathway.imageOrVideoUrl) && (
//                   <CardMedia
//                     component="img"
//                     alt={pathway.title}
//                     height="140"
//                     image={pathway.imageOrVideoUrl}
//                     title={pathway.title}
//                   />
//                 )}
//                 {isVideo(pathway.imageOrVideoUrl) && (
//                   <CardMedia
//                     component="video"
//                     controls
//                     height="240"
//                     src={pathway.imageOrVideoUrl}
//                     title={pathway.title}
//                   />
//                 )}
//               </>
//             )}
//             <CardContent>
//               <Typography variant="h6">{pathway.title}</Typography>
//               <Typography variant="body2" color="textSecondary">{pathway.caption}</Typography>

//               {/* Add the approach field below the caption */}
//               {pathway.approach && (
//                 <Typography variant="body2" color="textPrimary" style={{ marginTop: '10px' }}>
//                   <strong>Approach:</strong> {pathway.approach}
//                 </Typography>
//               )}

//               <Button
//                 variant="contained"
//                 color="success"
//                 href={pathway.linkToContent}
//                 target="_blank"
//                 style={{ marginTop: '10px' }}
//               >
//                 Explore
//               </Button>
//             </CardContent>
//           </Card>
//         </Grid>
//       ))}
//     </Grid>
//   </div>
// )}

//     </div>
//   );
// };

// export default MainPage;


// with PaymentMethodChangeEvent,ent 
import React, { useEffect, useState, useContext } from 'react';
import { Grid, Card, CardContent, Typography, Button, CardMedia } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FullScreenDialog from './DialogComponent';
import PaymentContext from './paymentContext';  // Import PaymentContext

// MainPage Component
const MainPage = () => {
  const { paymentFlag } = useContext(PaymentContext);  // Use PaymentContext to check payment status
  const [personas, setPersonas] = useState([]);
  const [pathways, setPathways] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState(null);

  const personasSheetUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTKj7zd6AB8zvpDmBAnq3yrE6eEVgtMnzgl7Kc8SrolfQg2vAxlYWFXT0d1b1nW0Vtv1opNpNyh4Jbv/pub?output=tsv';
  const pathwaysSheetUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQUGq6rQtC5i_d5mqNG935wGkDvwotVNx8CUWOD_X8Y5DFmjtpRbIbz8N601jyuroVyGhhubaDdelLl/pub?output=tsv';

  useEffect(() => {
    const fetchPersonas = async () => {
      try {
        const response = await fetch(personasSheetUrl);
        const data = await response.text();
        setPersonas(tsvToJson(data));
      } catch (error) {
        console.error('Error fetching personas:', error);
      }
    };

    const fetchPathways = async () => {
      try {
        const response = await fetch(pathwaysSheetUrl);
        const data = await response.text();
        setPathways(tsvToJson(data));
      } catch (error) {
        console.error('Error fetching pathways:', error);
      }
    };

    fetchPersonas();
    fetchPathways();
  }, [personasSheetUrl, pathwaysSheetUrl]);

  const tsvToJson = (tsv) => {
    const lines = tsv.split('\n');
    const headers = lines[0].split('\t');
    return lines.slice(1).map((line) => {
      const values = line.split('\t');
      const obj = {};
      headers.forEach((header, index) => {
        obj[header.trim()] = values[index] ? values[index].trim() : '';
      });
      return obj;
    });
  };

  // Handle persona selection
  const handlePersonaSelect = (personaName) => {
    setSelectedPersona(personaName);
  };

  const getPathwaysForSelectedPersona = () => {
    return pathways.filter((p) => p.personaName === selectedPersona);
  };

  // Check if the URL is a video by extension
  const isVideo = (url) => {
    return url && (url.endsWith('.mp4') || url.endsWith('.webm') || url.endsWith('.ogg'));
  };

  // Check if the URL is an image by extension
  const isImage = (url) => {
    return url && (url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.gif'));
  };

  // Open modal with specific component
  const handleOpenModal = (componentName) => {
    setModalComponent(componentName);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setModalComponent(null);
  };

  // Open demo in a new tab
  const handleOpenDemo = (demourl) => {
    if (demourl) {
      window.open(demourl, '_blank');
    }
  };

  return (
    <div>
      <Typography variant="h3" gutterBottom style={{ textAlign: 'center', marginBottom: '20px' }}>
        Who are you? / Who do you relate to?
      </Typography>

      {/* Step 1: Show personas */}
      <Grid container spacing={3}>
        {personas.map((persona, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>{persona.name}</Typography>
                <Typography variant="body1"><strong>Role:</strong> {persona.role}</Typography>
                <Typography variant="body2"><strong>Current Situation:</strong> {persona.currentSituation}</Typography>
                <Typography variant="body2"><strong>Goal:</strong> {persona.goal}</Typography>
                <Typography variant="body2"><strong>Constraints:</strong> {persona.constraints}</Typography>
                <Button
                  variant={selectedPersona === persona.name ? "contained" : "outlined"}
                  color="primary"
                  onClick={() => handlePersonaSelect(persona.name)}
                  style={{ marginTop: '10px' }}
                >
                  {selectedPersona === persona.name ? "Selected" : "This sounds like me"}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Step 2: Show pathways based on selected persona */}
      {selectedPersona && (
        <div style={{ marginTop: '40px' }}>
          <Typography variant="h4" gutterBottom>
            Pathways for {selectedPersona}
          </Typography>
          <Grid container spacing={3}>
            {getPathwaysForSelectedPersona().map((pathway, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card>
                  {pathway.imageOrVideoUrl && (
                    <>
                      {isImage(pathway.imageOrVideoUrl) && (
                        <CardMedia
                          component="img"
                          alt={pathway.title}
                          height="140"
                          image={pathway.imageOrVideoUrl}
                          title={pathway.title}
                          style={{ marginBottom: '10px' }}
                        />
                      )}
                      {isVideo(pathway.imageOrVideoUrl) && (
                        <CardMedia
                          component="video"
                          controls
                          height="240"
                          src={pathway.imageOrVideoUrl}
                          title={pathway.title}
                          style={{ marginBottom: '10px', borderRadius: '4px' }}
                        />
                      )}
                    </>
                  )}
                  <CardContent>
                    <Typography variant="h6">{pathway.title}</Typography>
                    <Typography variant="body2" color="textSecondary">{pathway.caption}</Typography>

                    {/* Add the approach field below the caption */}
                    {pathway.approach && (
                      <Typography variant="body2" color="textPrimary" style={{ marginTop: '10px' }}>
                        <strong>Approach:</strong> {pathway.approach}
                      </Typography>
                    )}

                    {/* Show the modal button only if the componentName is present */}
                    {pathway.componentName && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenModal(pathway.componentName)}
                        style={{ marginTop: '10px', marginRight: '10px' }}
                      >
                        Open Modal
                      </Button>
                    )}

                    {/* Demo Button */}
                    {pathway.demourl && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleOpenDemo(pathway.demourl)}
                        style={{ marginTop: '10px', marginRight: '10px' }}
                      >
                        Demo
                      </Button>
                    )}

                    {/* Buy Button if user hasn't paid */}
                    {!paymentFlag && (
                      <Button
                        variant="contained"
                        color="primary"
                        href="/payment"
                        style={{ marginTop: '10px', marginRight: '10px' }}
                      >
                        Buy Access
                      </Button>
                    )}

                    {/* Explore Button if user has paid */}
                    {paymentFlag && (
                      <Button
                        variant="contained"
                        color="success"
                        href={pathway.linkToContent}
                        target="_blank"
                        style={{ marginTop: '10px' }}
                      >
                        Explore
                      </Button>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      {/* Modal to display additional components */}
      <FullScreenDialog
        isOpen={isOpen}
        handleClose={handleCloseModal}
        title="Component Modal"
      >
        {modalComponent ? React.createElement(modalComponent) : <Typography>No Component Selected</Typography>}
      </FullScreenDialog>
    </div>
  );
};

export default MainPage;
